import React, { PureComponent, Fragment } from "react";
import cx from "classnames";
import { navigate } from "gatsby";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Seo from "../components/Seo";
import Terms from "../components/Terms";

import "../styles/main.scss";
import styles from "./page.module.scss";

export default class TermsPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loaded: true
      });
      const loaderElem = document.getElementById("loader");
      if (loaderElem) {
        loaderElem.parentNode.removeChild(loaderElem);
      }
    }, 0);
  }

  render() {
    const { loaded } = this.state;
    return (
      <Fragment>
        <Seo title="Terms and Conditions" />
        <div className={cx(styles.PageWrapper, { [styles.Loaded]: loaded })}>
          <Navbar
            goTo={page => {
              navigate(`/#${page}`);
            }}
            pathname="terms"
          />
          <div
            style={{
              marginTop: "90px",
              height: "77vh",
              overflowY: "scroll"
            }}
          >
            <div
              style={{
                maxWidth: "800px"
              }}
            >
              <Terms />
            </div>
          </div>
          <Footer
            goTo={page => {
              navigate(`/#${page}`);
            }}
          />
        </div>
      </Fragment>
    );
  }
}
